<template>
  <div>
    <div class="landing">
      <transition name="fade-fast">
        <div class="landing2-img-container" v-show="mostrarImagen">
          <div class="landing2-img">
            <img
              v-if="$usuario.imagen == null"
              src="../assets/img/default.png"
            />
            <img
              v-if="$usuario.imagen != null"
              :src="$localurl + '/' + $usuario.imagen"
            />
          </div>
          <transition name="fade-fast">
            <label v-show="!mostrarListo" class="landing2-label"
              >Ingresando</label
            >
          </transition>
          <transition name="fade-fast">
            <label v-show="mostrarListo" class="landing2-label">Listo!</label>
          </transition>
        </div>
      </transition>
    </div>
    <transition name="fade-fast">
      <div class="landing" v-show="mostrarLanding">
        <div
          class="landing-top-container"
          :class="{ 'landing-top-container-expandido': topExpandido }"
        >
          <img
            v-show="fondoElegido == null"
            class="landing-top-container-fondo"
            :src="$localurl + '/' + configuracion.fondo"
          />
          <img
            v-show="fondoElegido != null"
            class="landing-top-container-fondo"
            :src="getFondo()"
          />
          <div class="landing-top-mask"></div>
          <div class="landing-top">
            <div class="app-mobile-no landing-top-usuario" style="z-index: 10">
              <div class="landing-top-usuario-herramientas">
                <label
                  v-if="$usuario.rolClientes != null"
                  @click="irA('mialta')"
                  >Mi Alta</label
                >
                <div v-if="$usuario.rolClientes != null"></div>
                <label @click="irA('documentos')">Mis Documentos</label>
              </div>
              <div class="landing-top-hola">
                <div>
                  <p class="landing-top-hola-nombre">
                    <b>{{ getNombre() }}</b>
                  </p>
                  <p class="landing-top-logout">
                    {{ $usuario.email }}
                  </p>
                </div>
                <div
                  class="landing-top-user-img-container"
                  @click="mostrarLogoutMenu = true"
                >
                  <img
                    v-if="$usuario.imagen == null"
                    class="landing-top-user-img"
                    src="../assets/img/default.png"
                  />
                  <img
                    class="landing-top-user-img"
                    v-else
                    :src="$localurl + '/' + $usuario.imagen"
                  />
                  <img
                    v-if="getReemplazoId() && getReemplazanteImagen()"
                    class="landing-top-user-img landing-top-reemplazo-img"
                    :src="$localurl + '/' + getReemplazanteImagen()"
                  />
                  <img
                    v-else-if="getReemplazoId()"
                    class="landing-top-user-img landing-top-reemplazo-img"
                    src="../assets/img/default.png"
                  />
                  <img
                    class="landing-top-user-menu"
                    src="../assets/img/flechas/triangle_bottom.png"
                  />
                </div>
                <div v-show="mostrarLogoutMenu" class="landing-top-logout-menu">
                  <div
                    class="landing-top-logout-menu-mask"
                    @click="mostrarLogoutMenu = false"
                  ></div>
                  <div class="landing-top-logout-menu-popup">
                    <label
                      v-if="$usuario.rolClientes.rol != 'Distribuidor'"
                      @click="irAMiCuenta()"
                      >Mi cuenta</label
                    >
                    <label
                      v-if="
                        $usuario.rolClientes.rol != 'Distribuidor' &&
                          tieneReemplazos()
                      "
                      @click="irASeleccionarCuenta()"
                      >Seleccionar cuenta</label
                    >
                    <label
                      class="landing-top-logout-menu-popup-logout"
                      @click="logout()"
                      >Cerrar sesión</label
                    >
                  </div>
                </div>
              </div>
            </div>
            <div class="app-solo-mobile landing-top-menu">
              <img
                src="../assets/img/icons/menu.png"
                @click="mostrarMiAltaMenu = true"
              />
            </div>
            <div
              class="app-solo-mobile landing-top-user-img-container"
              @click="mostrarLogoutMenu = true"
            >
              <img
                class="landing-top-user-menu"
                src="../assets/img/flechas/triangle_bottom.png"
              />
              <img
                v-if="$usuario.imagen == null"
                class="landing-top-user-img"
                src="../assets/img/default.png"
              />
              <img
                class="landing-top-user-img"
                v-else
                :src="$localurl + '/' + $usuario.imagen"
              />
              <img
                v-if="getReemplazoId() && getReemplazanteImagen()"
                class="landing-top-user-img landing-top-reemplazo-img"
                :src="$localurl + '/' + getReemplazanteImagen()"
              />
              <img
                v-else-if="getReemplazoId()"
                class="landing-top-user-img landing-top-reemplazo-img"
                src="../assets/img/default.png"
              />
              <div v-show="mostrarLogoutMenu" class="landing-top-logout-menu">
                <div
                  class="landing-top-logout-menu-mask"
                  @click.stop="mostrarLogoutMenu = false"
                ></div>
                <div class="landing-top-logout-menu-popup">
                  <label
                    v-if="$usuario.rolClientes.rol != 'Distribuidor'"
                    @click="irAMiCuenta()"
                    >Mi cuenta</label
                  >
                  <label
                    v-if="
                      $usuario.rolClientes.rol != 'Distribuidor' &&
                        tieneReemplazos()
                    "
                    @click="irASeleccionarCuenta()"
                    >Seleccionar cuenta</label
                  >
                  <label
                    class="landing-top-logout-menu-popup-logout"
                    @click="logout()"
                    >Cerrar sesión</label
                  >
                </div>
              </div>
              <div v-show="mostrarMiAltaMenu" class="landing-top-logout-menu">
                <div
                  class="landing-top-logout-menu-mask"
                  @click.stop="mostrarMiAltaMenu = false"
                ></div>
                <div class="landing-top-mialta-menu-popup">
                  <label
                    v-if="$usuario.rolClientes != null"
                    @click="irA('mialta')"
                    >Mi Alta</label
                  >
                  <label @click="irA('documentos')">Mis Documentos</label>
                </div>
              </div>
            </div>
            <div class="landing-logo">
              <img src="../assets/img/logo.png" />
            </div>
          </div>
          <div class="landing-top-sesion">
            <div
              class="landing-top-fondos-mask"
              v-show="mostrarPerfil"
              @click="mostrarPerfil = false"
            ></div>
            <div
              class="landing-top-perfil-container"
              v-show="mostrarPerfil"
              :class="{
                'landing-top-perfil-container-expandido': !topExpandido
              }"
            >
              <img
                class="landing-top-perfil-close"
                src="../assets/img/icons/close.png"
                @click="mostrarPerfil = false"
              />
              <div class="landing-top-perfil-top">
                <div
                  class="landing-top-perfil-top-img-container"
                  @click="cambiarImagenUsuario()"
                >
                  <img
                    v-if="$usuario.imagen == null"
                    class="landing-top-perfil-top-img"
                    src="../assets/img/default.png"
                  />
                  <img
                    v-if="$usuario.imagen != null"
                    class="landing-top-perfil-top-img"
                    :src="$localurl + '/' + $usuario.imagen"
                  />
                  <img
                    class="landing-top-perfil-top-img-editar"
                    src="../assets/img/edit_circle.png"
                  />
                </div>
                <div>
                  <label class="landing-top-perfil-nombre">{{
                    $usuario.nombre
                  }}</label>
                  <label class="landing-top-perfil-email">{{
                    $usuario.email
                  }}</label>
                </div>
              </div>
              <div>
                <div class="landing-top-perfil-row">
                  <img
                    src="../assets/img/perfil/imagen.png"
                    @click="abrirFondos()"
                  />
                  <label @click="abrirFondos()">Personalizar</label>
                  <input
                    class="app-hide"
                    type="file"
                    ref="imagenUsuarioUpload"
                    @change="changeImagenUsuario()"
                    accept="image/png, image/jpeg"
                  />
                </div>
                <div class="landing-top-perfil-row">
                  <img
                    src="../assets/img/perfil/logout.png"
                    @click="logout()"
                  />
                  <label @click="logout()">Cerrar sesión</label>
                </div>
              </div>
            </div>
            <img
              class="app-mobile-no landing-top-sesion-fondo"
              src="../assets/img/icons/fondo.png"
              @click="mostrarFondos = true"
            />
            <img
              v-show="!topExpandido"
              class="app-mobile-no landing-top-sesion-expandir"
              src="../assets/img/icons/expandir.png"
              @click="expandir(true)"
            />
            <img
              v-show="topExpandido"
              class="app-mobile-no landing-top-sesion-expandir"
              src="../assets/img/icons/contraer.png"
              @click="expandir(false)"
            />
            <div
              class="landing-top-fondos-mask"
              v-show="mostrarFondos"
              @click="mostrarFondos = false"
            ></div>
            <div
              class="landing-top-fondos-container"
              v-show="mostrarFondos"
              :class="{
                'landing-top-fondos-container-expandido': !topExpandido
              }"
            >
              <label>Perzonalizá Mi Cuenta BASF</label>
              <div class="landing-top-fondos-fondo-container">
                <div>
                  <div class="landing-top-fondos-fondo" @click="elegirFondo(1)">
                    <img src="../assets/img/fondos/fondo1.jpg" />
                    <div>
                      <img
                        v-show="fondoElegido == 1"
                        src="../assets/img/icons/check.png"
                      />
                    </div>
                  </div>
                  <div class="landing-top-fondos-fondo" @click="elegirFondo(2)">
                    <img src="../assets/img/fondos/fondo2.jpg" />
                    <div>
                      <img
                        v-show="fondoElegido == 2"
                        src="../assets/img/icons/check.png"
                      />
                    </div>
                  </div>
                  <div class="landing-top-fondos-fondo" @click="elegirFondo(3)">
                    <img src="../assets/img/fondos/fondo3.jpg" />
                    <div>
                      <img
                        v-show="fondoElegido == 3"
                        src="../assets/img/icons/check.png"
                      />
                    </div>
                  </div>
                  <div class="landing-top-fondos-fondo" @click="elegirFondo(4)">
                    <img src="../assets/img/fondos/fondo4.jpg" />
                    <div>
                      <img
                        v-show="fondoElegido == 4"
                        src="../assets/img/icons/check.png"
                      />
                    </div>
                  </div>
                  <div class="landing-top-fondos-fondo" @click="elegirFondo(5)">
                    <img src="../assets/img/fondos/fondo5.jpg" />
                    <div>
                      <img
                        v-show="fondoElegido == 5"
                        src="../assets/img/icons/check.png"
                      />
                    </div>
                  </div>
                  <div class="landing-top-fondos-fondo" @click="elegirFondo(6)">
                    <img src="../assets/img/fondos/fondo6.jpg" />
                    <div>
                      <img
                        v-show="fondoElegido == 6"
                        src="../assets/img/icons/check.png"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="landing-top-fondos-subir">
                <div
                  class="landing-top-fondos-subir-btn"
                  @click="cambiarImagen()"
                >
                  <label>Subir una Imagen</label>
                  <img src="../assets/img/flechas/top_blue.png" />
                </div>
                <input
                  class="app-hide"
                  type="file"
                  ref="imagenUpload"
                  @change="changeImagen()"
                  accept="image/png, image/jpeg"
                />
              </div>
              <button
                class="landing-top-fondos-listo"
                @click="mostrarFondos = false"
              >
                Listo
              </button>
            </div>
          </div>
          <div class="landing-herramientas">
            <div class="landing-row">
              <div
                class="landing-opcion landing-opcion-permisos"
                @click="irAPermisos()"
                v-if="esPerformance()"
              >
                <div class="landing-opcion-img">
                  <img src="../assets/img/landing/permisos.png" />
                </div>
                <p class="landing-opcion-text">Permisos</p>
              </div>
              <div
                class="landing-opcion landing-opcion-mialta"
                @click="irA('mialta')"
                v-if="$usuario.rolClientes != null && false"
              >
                <div class="landing-opcion-img">
                  <img src="../assets/img/landing/mialta.png" />
                </div>
                <p class="landing-opcion-text">Mi Alta</p>
              </div>
              <div
                class="landing-opcion landing-opcion-cyo"
                @click="chequearIrA('cyo')"
                v-if="$usuario.rolCYO != null"
              >
                <div class="landing-opcion-img">
                  <img src="../assets/img/landing/cyo.png" />
                </div>
                <p class="landing-opcion-text">Mi Cuenta y Orden</p>
              </div>
              <div
                class="landing-opcion landing-opcion-cc"
                @click="chequearIrA('cc')"
                v-if="$usuario.rolCC != null"
              >
                <div class="landing-opcion-img">
                  <img src="../assets/img/landing/cc.png" />
                </div>
                <p class="landing-opcion-text">Mi Cuenta Corriente</p>
              </div>

              <div
                v-if="mostrarMisPedidos()"
                class="landing-opcion landing-opcion-pog"
                @click="irA('mispedidos')"
              >
                <div class="landing-opcion-img">
                  <img src="../assets/img/landing/mis-pedidos.png" />
                </div>
                <p class="landing-opcion-text">Mis Pedidos</p>
              </div>

              <div
                class="landing-opcion landing-opcion-pog"
                @click="irA('pog')"
                v-if="$usuario.rolPOG != null"
              >
                <div class="landing-opcion-img">
                  <img src="../assets/img/landing/pog.png" />
                </div>
                <p class="landing-opcion-text">Mi POG</p>
              </div>
              <div
                class="landing-opcion landing-opcion-cyo"
                @click="irA('simulador')"
                v-if="$usuario.rolPlanComercial != null"
              >
                <div class="landing-opcion-img">
                  <img src="../assets/img/landing/planes.png" />
                </div>
                <p class="landing-opcion-text">Mi Plan Comercial</p>
              </div>

              <div
                class="landing-opcion landing-opcion-herramienta"
                v-for="herramienta in configuracion.herramientas"
                :key="herramienta.id"
                @click="irAHerramienta(herramienta.link)"
              >
                <div class="landing-herramienta-opciones">
                  <img
                    src="../assets/img/herramienta/editar.png"
                    @click.stop="editarHerramienta(herramienta)"
                  />
                  <img
                    src="../assets/img/herramienta/eliminar.png"
                    @click.stop="eliminarHerramienta(herramienta)"
                  />
                </div>
                <div class="landing-opcion-img">
                  <img
                    class="landing-herramienta-fondo"
                    :src="$localurl + '/' + herramienta.imagen"
                  />
                </div>
                <p class="landing-opcion-text">{{ herramienta.nombre }}</p>
              </div>
              <div
                class="landing-opcion landing-opcion-metricas"
                v-if="getRolUsuario()"
                @click="$router.push('/metricas')"
              >
                <div class="landing-opcion-img">
                  <img src="../assets/img/landing/metricas.png" />
                </div>
                <p class="landing-opcion-text">Métricas</p>
              </div>
              <div class="landing-opcion" @click="abrirHerramientas()">
                <div class="landing-opcion-img">
                  <img src="../assets/img/landing/agregar.png" />
                </div>
                <p class="landing-opcion-text">Agregar</p>
              </div>
            </div>
          </div>
        </div>
        <p class="landing-top-hola app-solo-mobile">
          Hola
          {{
            $usuario != null && $usuario.nombre != null
              ? $usuario.nombre.split(" ")[0]
              : ""
          }}, bienvenido
        </p>
        <div class="landing-body">
          <div>
            <label class="landing-body-title">Tu resumen</label>
            <label class="landing-body-subtitle"
              >Encontrarás las novedades relacionadas a tu perfil aquí</label
            >
            <div
              class="landing-body-resumen app-mobile-no"
              v-if="getRolUsuario()"
            >
              <img
                v-if="$usuario.imagen == null"
                src="../assets/img/default.png"
              />
              <img
                v-if="$usuario.imagen != null"
                :src="$localurl + '/' + $usuario.imagen"
              />
              <label @click="abrirAnuncio()"
                >{{ getNombre() }}, ¿Algo nuevo para comunicar?</label
              >
            </div>
            <div class="landing-mobile-anuncios">
              <div>
                <div class="landing-anuncios">
                  <Anuncio
                    v-for="anuncio in anuncios"
                    :key="anuncio.id"
                    :anuncio="anuncio"
                  ></Anuncio>
                </div>
                <CC v-if="$usuario.rolCC != null"></CC>
                <POG v-if="$usuario.rolPOG != null"></POG>
                <CYO v-if="$usuario.rolCYO != null"></CYO>
                <Clientes v-if="$usuario.rolClientes != null"></Clientes>
              </div>
            </div>
          </div>
          <div>
            <label class="landing-body-title">Novedades BASF</label>
            <label class="landing-body-subtitle"
              >Todo lo relacionado a nuestros clientes.</label
            >
            <img
              v-if="
                $usuario.email == 'ana.spioussas@basf.com' ||
                  $usuario.email == 'maria-ana.laguzzi@basf.com' ||
                  $usuario.email == 'gustavo.saldania@basf.com' ||
                  $usuario.email == 'jeronimo.carmody-fernandez@basf.com'
              "
              @click="abrirNovedades()"
              class="app-mobile-no landing-body-add"
              src="../assets/img/botones/agregar.png"
            />
            <div class="landing-novedades">
              <Novedad
                v-for="novedad in novedades"
                :key="novedad.id"
                :novedad="novedad"
              ></Novedad>
            </div>
          </div>
        </div>
        <div class="landing-footer">
          <div class="landing-footer-row">
            <label>Seguinos</label>
            <div class="landing-footer-redes">
              <img
                src="../assets/img/redes/basf.svg"
                @click="irARedes('https://agriculture.basf.com/ar/es.html')"
              />
              <img
                src="../assets/img/redes/fb.svg"
                @click="irARedes('https://www.facebook.com/BASF.AgroAR/')"
              />
              <img
                src="../assets/img/redes/tw.svg"
                @click="irARedes('https://twitter.com/BASF_Agro_ARG')"
              />
              <img
                src="../assets/img/redes/linkedin.svg"
                @click="irARedes('https://www.linkedin.com/company/basf/')"
              />
              <img
                src="../assets/img/redes/ig.svg"
                @click="irARedes('https://www.instagram.com/basfagro_arg/')"
              />
              <img
                src="../assets/img/redes/yt.svg"
                @click="
                  irARedes(
                    'https://www.youtube.com/channel/UCoDGtUf4DcRCEUZwPQODi8A'
                  )
                "
              />
            </div>
          </div>
          <div class="landing-footer-row">
            <label>Contactános</label>
            <div>
              <div class="landing-footer-contacto" @click="abrirWhatsapp()">
                <img src="../assets/img/botones/leer_mas.png" />
                <label>Contactános vía Whatsapp</label>
              </div>
              <div class="landing-footer-contacto" @click="abrirCallCenter()">
                <img src="../assets/img/botones/leer_mas.png" />
                <label>Call Center: 0800-555-2273</label>
              </div>
              <div
                class="landing-footer-contacto"
                @click="
                  irARedes(
                    'https://agriculture.basf.com/ar/es/legal/contacto.html'
                  )
                "
              >
                <img src="../assets/img/botones/leer_mas.png" />
                <label>Formulario de contacto</label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
    <Herramienta></Herramienta>
    <AnuncioPopup></AnuncioPopup>
    <NovedadPopup></NovedadPopup>
    <SelectClientPopup
      ref="selectClientPopup"
      :title="irATitle"
      :modulo="irAModulo"
      :callback="soldToPopupCallback"
    ></SelectClientPopup>
    <transition name="fade-fast">
      <Recorrido v-if="mostrarRecorrido"></Recorrido>
    </transition>
    <ReemplazoPopup
      v-for="reemplazo in reemplazosNoVistos"
      :key="reemplazo.id"
      :reemplazo="reemplazo"
    ></ReemplazoPopup>
  </div>
</template>

<script>
import Vue from "vue";
import VueCookies from "vue-cookies";
Vue.use(VueCookies);

import Herramienta from "@/components/Herramienta.vue";
import AnuncioPopup from "@/components/AnuncioPopup.vue";
import NovedadPopup from "@/components/NovedadPopup.vue";
import SelectClientPopup from "@/components/SelectClientPopup.vue";
import Anuncio from "@/components/Anuncio.vue";
import Novedad from "@/components/Novedad.vue";
import POG from "@/components/tarjetas/POG.vue";
import CYO from "@/components/tarjetas/CYO.vue";
import Clientes from "@/components/tarjetas/Clientes.vue";
import CC from "@/components/tarjetas/CC.vue";
import Recorrido from "@/components/Recorrido.vue";
import ReemplazoPopup from "@/components/ReemplazoPopup.vue";
import { ordenarJson } from "@/assets/js/general.js";

export default {
  name: "landing",
  components: {
    Herramienta,
    AnuncioPopup,
    NovedadPopup,
    SelectClientPopup,
    Anuncio,
    Novedad,
    POG,
    CYO,
    Clientes,
    CC,
    Recorrido,
    ReemplazoPopup
  },
  data() {
    return {
      fechaHoy: "",
      horaHoy: "",
      dias: [
        "Domingo",
        "Lunes",
        "Martes",
        "Miércoles",
        "Jueves",
        "Viernes",
        "Sábado"
      ],
      meses: [
        "Ene",
        "Feb",
        "Mar",
        "Abr",
        "May",
        "Jun",
        "Jul",
        "Ago",
        "Sep",
        "Oct",
        "Nov",
        "Dic"
      ],
      mostrarLanding: true,
      mostrarListo: false,
      mostrarImagen: false,
      mostrarLogoutMenu: false,
      mostrarMiAltaMenu: false,
      topExpandido: false,
      configuracion: {},
      mostrarPerfil: false,
      mostrarFondos: false,
      fondoElegido: null,
      anuncios: [],
      novedades: [],
      liveAnda: true,
      mostrarRecorrido: false,
      irATitle: null,
      irAModulo: null,
      reemplazosNoVistos: []
    };
  },
  created() {},
  mounted() {
    this.getFechaHoy();
    this.getHoraHoy();
    this.getConfiguracion();
    this.getAnuncios();
    this.getNovedades();
    this.getLive();
    this.getReemplazosNoVistos();
    this.$eventHub.$on("update", () => this.$forceUpdate());
    this.$eventHub.$on("herramienta-nueva", herramienta =>
      this.nuevaHerramienta(herramienta)
    );
    this.$eventHub.$on("anuncio-nuevo", () => this.getAnuncios());
    this.$eventHub.$on("novedad-nuevo", () => this.getNovedades());
    this.$eventHub.$on("recorrido-listo", () => this.getConfiguracion());
  },
  watch: {},
  methods: {
    ordenarJson: function(json, prop, asc) {
      return ordenarJson(json, prop, asc);
    },
    abrirFondos() {
      this.mostrarFondos = true;
      this.mostrarPerfil = false;
    },
    getReemplazanteImagen() {
      const reemplazante = this.$store.getters.reemplazante;
      return reemplazante != null ? reemplazante.imagen : null;
    },
    getReemplazoId() {
      return localStorage.getItem("reemplazo-id");
    },
    getNombre() {
      if (
        this.$usuario.nombre != null &&
        this.$usuario.email.split("@")[1].split(".")[0] == "basf"
      ) {
        return this.$usuario.nombre.split(" ")[0];
      } else if (this.$usuario.nombre != null) {
        return this.$usuario.nombre;
      } else {
        return "";
      }
    },
    getFondo() {
      if (this.fondoElegido != null) {
        return require("@/assets/img/fondos/fondo" +
          this.fondoElegido +
          ".jpg");
      }
    },
    // Getting the configuration from the server.
    getConfiguracion() {
      this.mostrarRecorrido = false;
      const that = this;
      this.$axios
        .get(this.$localurl + "/api/configuracion")
        .then(function(response) {
          if (response.data != "") {
            that.configuracion = response.data;
            that.configuracion.herramientas = that.ordenarJson(
              that.configuracion.herramientas,
              "nombre",
              true
            );
            that.topExpandido = that.configuracion.expandido;
            that.fondoElegido = that.configuracion.fondoElegido;
            if (that.configuracion.primeraVez == true) {
              that.mostrarRecorrido = true;
            }
          }
        })
        .catch(function(response) {
          console.log(response);
          // handle error
          if (
            window.location.pathname != "/login" &&
            window.location.pathname.split("/")[1] != "password" &&
            window.location.pathname.split("/")[3] != "password"
          ) {
            that.$router.push("/login");
          }
        });
    },
    // Making a PUT request to the server and sending the data in the configuracion object.
    guardarConfiguracion() {
      const that = this;
      this.$axios
        .put(this.$localurl + "/api/configuracion", this.configuracion)
        .then(function(response) {
          that.configuracion = {};
          that.$nextTick(() => {
            that.configuracion = response.data;
          });
        })
        .catch(function(response) {
          console.log(response);
        });
    },
    // Getting the current date and time and updating it every hour.
    getFechaHoy() {
      let d = new Date();
      this.fechaHoy =
        this.dias[d.getDay()] +
        " " +
        d.getDate() +
        " " +
        this.meses[d.getMonth()] +
        ", " +
        d.getFullYear();
      const that = this;
      setTimeout(function() {
        that.getFechaHoy();
      }, 3600000);
    },
    getHoraHoy() {
      let d = new Date();
      let hora = d.getHours().toString();
      if (hora.length == 1) {
        hora = "0" + hora;
      }
      let minutos = d.getMinutes().toString();
      if (minutos.length == 1) {
        minutos = "0" + minutos;
      }
      this.horaHoy = hora + ":" + minutos + " hs";
      const that = this;
      setTimeout(function() {
        that.getHoraHoy();
      }, 1000);
    },
    // obtengo todos los reemplazos mayores a la fecha actual que no hayan sido vistos
    getReemplazosNoVistos() {
      const that = this;
      this.$axios
        .get(this.$localurl + "/api/reemplazos/novistos")
        .then(function(response) {
          if (response.data != "") {
            that.reemplazosNoVistos = response.data;
          }
        })
        .catch(function(response) {
          console.log(response);
        });
    },
    tieneReemplazos() {
      if (
        this.$store.getters.reemplazos != null &&
        this.$store.getters.reemplazos.length > 0
      ) {
        return true;
      } else {
        return false;
      }
    },
    chequearIrA(text) {
      this.irAModulo = text;
      switch (text) {
        case "cc":
          if (this.$usuario.email.toLowerCase().includes("basf.com")) {
            this.irA(text);
          } else {
            this.irATitle = "cuenta corriente";
            this.$refs.selectClientPopup.open();
          }
          break;
        case "cyo":
          if (this.$usuario.email.toLowerCase().includes("basf.com")) {
            this.irA(text);
          } else {
            const that = this;
            this.$axios
              .get(this.$localurl + "/api/cyo/muchos-clientes")
              .then(function() {
                that.irA(text);
              })
              .catch(function() {
                that.irATitle = "cuenta y orden";
                that.$refs.selectClientPopup.open();
              });
          }
          break;
        default:
          break;
      }
    },
    irA(text) {
      this.postMetrica(text);
      const that = this;
      if (text != "planes") {
        let token = this.$cookies.get("token-landing");
        if (token == null) {
          token = localStorage.getItem("token-landing");
        }
        let ruta = "";
        switch (text) {
          case "mialta":
            ruta = this.$mialta_url + "/landing/" + token;
            break;
          case "cyo":
            ruta = this.$cyo_url + "/landing/" + token;
            break;
          case "cc":
            if (this.$dev) {
              ruta =
                "https://dev.micuenta.basf.com.ar/cuenta-corriente/landing/" +
                token;
            } else {
              ruta = "https://micuentacorriente.basf.com/landing/" + token;
            }
            break;
          case "pog":
            ruta = this.$pog_url + "/landing/" + token;
            break;
          case "mispedidos":
            if (this.$dev) {
              ruta =
                "https://dev.micuenta.basf.com.ar/mis-pedidos/landing?auth=" +
                token;
            } else {
              ruta =
                "https://micuenta.agro.basf.com/mis-pedidos/landing?auth=" +
                token;
            }
            break;

          case "simulador":
            ruta = this.$dev
              ? "https://dev.micuenta.basf.com.ar/simulador"
              : "https://micuenta.agro.basf.com/simulador";
            if (this.$usuario.email.toLowerCase().includes("basf.com")) {
              ruta =
                ruta +
                "/plancomercial/planificacion?auth=" +
                token +
                "&admin=true";
            } else ruta = ruta + "/landing?auth=" + token;
            break;
          case "documentos":
            if (this.$dev) {
              ruta =
                "https://dev.micuenta.basf.com.ar/misdocumentos/token/" + token;
            } else {
              ruta =
                "https://micuenta.agro.basf.com/misdocumentos/token/" + token;
            }
            break;
          default:
            break;
        }
        if (ruta != "") {
          this.mostrarLanding = false;
          setTimeout(function() {
            that.mostrarImagen = true;
            setTimeout(function() {
              that.mostrarListo = true;
              window.location.href = ruta;
            }, 700);
          }, 800);
        }
      } else {
        this.mostrarLanding = false;
        this.mostrarImagen = true;
        this.$axios
          .get(this.$localurl + "/api/planes")
          .then(function(response) {
            console.log(response);
            that.mostrarListo = true;
            window.location.href = response.data;
          })
          .catch(function(response) {
            console.log(response);
          });
      }
    },
    logout() {
      localStorage.removeItem("token-landing");
      this.$store.commit("setReemplazante", null);
      localStorage.removeItem("reemplazo-id");
      this.$cookies.remove("token-landing");
      const that = this;
      this.$axios
        .get(this.$localurl + "/perform_logout")
        .then(function() {
          Vue.prototype.$usuario = {
            rol: {},
            rolClientes: {}
          };
          that.$router.push("/login");
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    irAMiCuenta() {
      localStorage.setItem("mialta-irA-micuenta", true);
      this.irA("mialta");
    },
    irASeleccionarCuenta() {
      this.$router.push("/seleccionarCuenta");
    },
    irAPermisos() {
      this.$router.push("/permisos");
    },
    irARedes(ruta) {
      window.open(ruta, "_blank").focus();
    },
    expandir(val) {
      this.topExpandido = val;
      this.configuracion.expandido = val;
      this.guardarConfiguracion();
    },
    elegirFondo(num) {
      this.fondoElegido = num;
      this.configuracion.fondoElegido = num;
      this.guardarConfiguracion();
    },
    cambiarImagen() {
      this.$refs.imagenUpload.click();
    },
    changeImagen() {
      const that = this;
      let reader = new FileReader();

      // Create a preview once image has loaded
      reader.onload = function() {
        that.reduceImageSize();
      };
      reader.readAsDataURL(this.$refs.imagenUpload.files[0]);
    },
    reduceImageSize() {
      // Read in file
      let file = this.$refs.imagenUpload.files[0];
      const that = this;
      // Ensure it's an image
      if (file.type.match(/image.*/)) {
        console.log("An image has been loaded");

        // Load the image
        let reader = new FileReader();
        reader.onload = function(readerEvent) {
          let image = new Image();
          image.onload = function() {
            // Resize the image
            let canvas = document.createElement("canvas"),
              max_size = 1150, // TODO : pull max size from a site config
              width = image.width,
              height = image.height;
            if (width > height) {
              if (width > max_size) {
                height *= max_size / width;
                width = max_size;
              }
            } else {
              if (height > max_size) {
                width *= max_size / height;
                height = max_size;
              }
            }
            canvas.width = width;
            canvas.height = height;
            canvas.getContext("2d").drawImage(image, 0, 0, width, height);
            let dataUrl = canvas.toDataURL("image/jpeg");
            that.configuracion.fondo = dataUrl.replace(
              /^data:image\/(png|jpg|jpeg);base64,/,
              ""
            );
            that.configuracion.fondoElegido = null;
            that.fondoElegido = null;
            that.guardarConfiguracion();
          };
          image.src = readerEvent.target.result;
        };
        reader.readAsDataURL(file);
      }
    },
    cambiarImagenUsuario() {
      this.$refs.imagenUsuarioUpload.click();
    },
    changeImagenUsuario() {
      const that = this;
      let reader = new FileReader();

      // Create a preview once image has loaded
      reader.onload = function() {
        that.reduceImageSizeUsuario();
      };
      reader.readAsDataURL(this.$refs.imagenUsuarioUpload.files[0]);
    },
    reduceImageSizeUsuario() {
      // Read in file
      let file = this.$refs.imagenUsuarioUpload.files[0];
      const that = this;
      // Ensure it's an image
      if (file.type.match(/image.*/)) {
        console.log("An image has been loaded");

        // Load the image
        let reader = new FileReader();
        reader.onload = function(readerEvent) {
          let image = new Image();
          image.onload = function() {
            // Resize the image
            let canvas = document.createElement("canvas"),
              max_size = 300, // TODO : pull max size from a site config
              width = image.width,
              height = image.height;
            if (width > height) {
              if (width > max_size) {
                height *= max_size / width;
                width = max_size;
              }
            } else {
              if (height > max_size) {
                width *= max_size / height;
                height = max_size;
              }
            }
            canvas.width = width;
            canvas.height = height;
            canvas.getContext("2d").drawImage(image, 0, 0, width, height);
            let dataUrl = canvas.toDataURL("image/jpeg");
            that.$usuario.imagen = dataUrl.replace(
              /^data:image\/(png|jpg|jpeg);base64,/,
              ""
            );
            that.guardarUsuario();
          };
          image.src = readerEvent.target.result;
        };
        reader.readAsDataURL(file);
      }
    },
    abrirHerramientas() {
      this.$eventHub.$emit("herramienta-abrir");
    },
    nuevaHerramienta(herramienta) {
      if (this.configuracion.herramientas.some(h => h.id == herramienta.id)) {
        this.configuracion.herramientas = this.configuracion.herramientas.filter(
          h => h.id != herramienta.id
        );
      }
      this.configuracion.herramientas.push(herramienta);
      this.guardarConfiguracion();
    },
    irAHerramienta(link) {
      window.open(link, "_blank").focus();
    },
    editarHerramienta(herramienta) {
      this.$eventHub.$emit("herramienta-editar", herramienta);
    },
    eliminarHerramienta(herramienta) {
      this.configuracion.herramientas = this.configuracion.herramientas.filter(
        h => h.id != herramienta.id
      );
      this.guardarConfiguracion();
    },
    abrirAnuncio() {
      this.$eventHub.$emit("anuncio-abrir");
    },
    abrirNovedades() {
      this.$eventHub.$emit("novedad-abrir");
    },
    getAnuncios() {
      const that = this;
      this.anuncios = [];
      this.$axios
        .get(this.$localurl + "/api/anuncios")
        .then(function(response) {
          if (response.data != "") {
            that.anuncios = response.data;
          }
        })
        .catch(function() {
          // handle error
          if (window.location.pathname != "/login") {
            that.$router.push("/login");
          }
        });
    },
    getNovedades() {
      const that = this;
      this.novedades = [];
      this.$axios
        .get(this.$localurl + "/api/novedades")
        .then(function(response) {
          if (response.data != "") {
            that.novedades = response.data;
          }
        })
        .catch(function() {
          // handle error
          if (window.location.pathname != "/login") {
            that.$router.push("/login");
          }
        });
    },
    esPerformance() {
      if (
        this.$usuario.rolClientes != null &&
        this.$usuario.rolClientes.detalle == "Performance" &&
        this.$usuario.rolCYO != null &&
        this.$usuario.rolCYO.detalle == "Performance" &&
        this.$usuario.rolPOG != null &&
        this.$usuario.rolPOG.detalle == "Performance" &&
        this.$usuario.rolCC != null &&
        this.$usuario.rolCC.detalle == "Performance"
      ) {
        return true;
      } else {
        return false;
      }
    },
    mostrarMisPedidos() {
      if (this.$dev) {
        // On dev, all clients from specific roles
        if (
          !this.$usuario?.rolClientes?.id ||
          this.$usuario?.rolClientes?.rol === "Agro"
        )
          return false;
        return [1, 2, 3, 4, 5, 6, 7, 8, 9, 20000].includes(
          this.$usuario.rolClientes.id
        );
      } else {
        //Only authorized emails for prd
        const authEmails = [
          "pilar.sabini@basf.com",
          "matias.a.vivot@basf.com",
          "jeronimo.carmody-fernandez@basf.com",
          "gustavo.saldania@basf.com",
          "luciana.torre@basf.com",
          "wanda.mollica@basf.com",
          "agustinamagrino@gmail.com",
          "federico.cespedes@basf.com",
          "adrian.amione@basf.com",
          "federico.carrizo@basf.com",
          "emmanuel.bertola@basf.com",
          "vjordan@cigra.com.ar",
          "frusso@ortegahermanos.com.ar",
          "lfernandez@agronasaja.com.ar",
          "jsills@pampasagro.com.ar",
          "agiordano@pampasagro.com.ar",
          "agroquimicaalberdi@gmail.com",
          "cpafumi@sinersa.com",
          "logisticacentral@ambito-das.com.ar",
          "insumos@asc-srl.com",
          "gaston.rossi@hab.com.ar"
        ];
        return authEmails.includes(this.$usuario.email?.toLowerCase());
      }
    },
    getRolUsuario() {
      if (
        (this.$usuario.rolClientes != null &&
          this.$usuario.rolClientes.detalle == "Performance") ||
        (this.$usuario.rolCYO != null &&
          this.$usuario.rolCYO.detalle == "Performance") ||
        (this.$usuario.rolPOG != null &&
          this.$usuario.rolPOG.detalle == "Performance") ||
        (this.$usuario.rolCC != null &&
          this.$usuario.rolCC.detalle == "Performance")
      ) {
        return true;
      } else {
        return false;
      }
    },
    guardarUsuario() {
      const that = this;
      this.$axios
        .put(this.$localurl + "/api/usuario/self", this.$usuario)
        .then(function(response) {
          that.$usuario = response.data;
          that.mostrarPerfil = false;
        })
        .catch(function() {});
    },
    abrirWhatsapp() {
      window.open("https://wa.me/5491134215552", "_blank").focus();
    },
    abrirCallCenter() {
      window.open("tel:0800-555-2273", "_blank").focus();
    },
    getLive() {
      const that = this;
      this.$axios
        .get(this.$localurl + "/api/planes")
        .then(function(response) {
          console.log(response);
        })
        .catch(function(response) {
          console.log(response);
          if (response.response.status == 500) {
            that.liveAnda = false;
          }
        });
    },
    postMetrica(modulo) {
      this.$axios
        .post(this.$localurl + "/api/metrica/" + modulo)
        .then(function(response) {
          console.log(response);
        })
        .catch(function(response) {
          console.log(response);
        });
    },
    soldToPopupCallback() {
      this.irA(this.irAModulo);
    }
  }
};
</script>

<style scoped src="../assets/css/views/landing.css"></style>
<style scoped src="../assets/css/app.css"></style>
